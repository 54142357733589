<script>
import { mapActions } from 'vuex'

export default {
    name: 'Logout',
    methods: {
        ...mapActions({ 'logout': 'auth/logout' })
    },
    created () {
        this.logout();
        this.$router.push('/');
    }
}
</script>

